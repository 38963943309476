// ProfileSection.js
import React from 'react';
import './ProfileSection.css';
import { useNavigate } from 'react-router-dom';





const ProfileSection = ({ profileImage, profileName, onLogout }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        onLogout();
        navigate('/'); // Redirect to the landing page
      };
      
  return (
    <div className="profile-card">
      <div className="profile-info">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <h2>{profileName}</h2>
        <button className="end-session-button" onClick={handleLogout}>
    <img src="/assets/logout.svg" alt="Logout Icon" className="logout-icon" />
  </button>
      </div>
    </div>
  );
};

export default ProfileSection;
