import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Leaderboard = ({ authToken, onClose }) => {
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  const [currentPlayerEntry, setCurrentPlayerEntry] = useState(null); // State for current player's entry
  const [offset, setOffset] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  const limit = 10; // Number of entries per page

  const fetchLeaderboard = async () => {
    const toastId = toast.loading('Fetching leaderboard...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
    });

    try {
      const response = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/leaderboards/get-details',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
            'Api-Key': '7e678ce98895855516462e8468322b1f7dbba1899fa869a265e09d22935690d5',
            env: 'console',
          },
          body: JSON.stringify({
            leaderboardId: 'snake_leaderboard_vv_001',
            limit: limit,
            offset: offset,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch leaderboard');
      }

      setLeaderboardEntries(data.data.leaderboardEntries);
      setTotalEntries(data.data.totalEntries);

      // Set the current player entry
      if (data.data.currentPlayerEntries.length > 0) {
        setCurrentPlayerEntry(data.data.currentPlayerEntries[0]);
      } else {
        setCurrentPlayerEntry(null);
      }

      toast.update(toastId, {
        render: 'Leaderboard fetched successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      toast.update(toastId, {
        render: `Error fetching leaderboard: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [offset]);

  const handleNext = () => {
    if (offset + limit < totalEntries) {
      setOffset(offset + limit);
    }
  };

  const handlePrevious = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

 


  return (
    <div className="leaderboard-modal" onClick={onClose}>
      <div className="leaderboard-modal-content" onClick={(e) => e.stopPropagation()}>
      <div className='popup-header'>
        <h1>Leaderboard</h1>
        <button className="leaderboard-close-button" onClick={onClose}>
       Back
        </button>
        </div>
        {leaderboardEntries.length === 0 && !currentPlayerEntry ? (
          <p>No entries available.</p>
        ) : (
          <table className="results-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Username</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {currentPlayerEntry && ( // Display the current player's entry at the top
                <tr key={currentPlayerEntry.userDetails.uuid} className={`rank-${currentPlayerEntry.rank}`}>
                  <td>
                    {currentPlayerEntry.rank === 1 ? (
                      <span className="rank-icon">&#x1F451;</span>
                    ) : (
                      currentPlayerEntry.rank
                    )}
                  </td>
                  <td>{currentPlayerEntry.userDetails.username || 'Unknown'}</td>
                  <td>{currentPlayerEntry.score}</td>
                </tr>
              )}
              {leaderboardEntries.map((entry) => (
                <tr key={entry.userDetails.uuid} className={`rank-${entry.rank}`}>
                  <td>
                    {entry.rank === 1 ? (
                      <span className="rank-icon">&#x1F451;</span>
                    ) : (
                      entry.rank
                    )}
                  </td>
                  <td>{entry.userDetails.username || 'Unknown'}</td>
                  <td>{entry.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination">
          <button onClick={handlePrevious} disabled={offset === 0}>
            Previous
          </button>
          <button onClick={handleNext} disabled={offset + limit >= totalEntries}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
