// SnakeGameHome.js
import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer, toastId } from 'react-toastify';
import Leaderboard from './Leaderboard';



const SnakeGameHome = ({ gameData, authToken, onStartGame, setMatchId,setMatchName  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const openLeaderboard = () => setIsLeaderboardOpen(true);
    const closeLeaderboard = () => setIsLeaderboardOpen(false);
  
    const formatContent = (text) => {
      return text
        .replace(/Objective:/g, '<strong>Objective:</strong>')
        .replace(/Controls:/g, '<strong>Controls:</strong>')
        .replace(/Start\/Restart:/g, '<strong>Start/Restart:</strong>')
        .replace(/Movement:/g, '<strong>Movement:</strong>')
        .replace(/Gameplay Tips:/g, '<strong>Gameplay Tips:</strong>')
        .replace(/Scoring and Intensity Levels:/g, '<strong>Scoring and Intensity Levels:</strong>')
        .replace(/\n/g, '<br><br>');
    };
    
    const fetchMatches = async () => {
        const toastId = toast.loading('Fetching matches...', {
          className: 'neu-toast neu-toast-info',
          progressClassName: 'neu-toast-progress',
        });
    
        try {
          const response = await fetch(
            'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-matches',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
                'Api-Key': '7e678ce98895855516462e8468322b1f7dbba1899fa869a265e09d22935690d5',
                'env': 'console',
              },
              body: JSON.stringify({}),
            }
          );
    
          const data = await response.json();
    
          if (!response.ok) {
            throw new Error(data.message || 'Failed to fetch matches');
          }
    
          const targetMatch = data.data.matches.find(match => match.id === 'snake_match_001');
          if (targetMatch) {
            setMatchId(targetMatch.id);
            setMatchName(targetMatch.name);  // Set the match name
            console.log(targetMatch.name)
            toast.update(toastId, {
              render: `Match "${targetMatch.name}" found!.`,
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            });
            onStartGame();
          } else {
            toast.update(toastId, {
              render: 'Match not found.',
              type: 'error',
              isLoading: false,
              autoClose: 3000,
            });
          }
        } catch (error) {
          console.error('Error fetching matches:', error);
          toast.update(toastId, {
            render: `Error fetching matches: ${error.message}`,
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        }
      };
  
  
    return (
        <div className="home-screen">
        <img src={gameData.iconUrl} alt={`${gameData.name} Icon`} className="game-icon" />
        <h1>{gameData.name}</h1>
        <button className="begin-button" onClick={fetchMatches}>
         Play Now
        </button>
        <button className="how-to-play-button" onClick={openModal}>
          How to Play
        </button>
  
      {isModalOpen && (
        <div className="how-to-play-modal" onClick={closeModal}>
              <div className='popup-header'>
       
          
            <h1>How to Play</h1>

            <button className="how-to-play-close-button" onClick={closeModal}>
             Back  {/* <img src={${process.env.PUBLIC_URL}/assets/close.svg} alt="Close" className="icon" /> */}
            </button>
          </div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div dangerouslySetInnerHTML={{ __html: formatContent(gameData.howTo) }} />
          </div>
        </div>
      )}
       <button className="how-to-play-button" onClick={openLeaderboard}>
        View Leaderboard
      </button>
    {isLeaderboardOpen && <Leaderboard authToken={authToken} onClose={closeLeaderboard} />}
    </div>
  );
};


export default SnakeGameHome;