class Snake {
    constructor() {
      this.body = [
        { x: 10, y: 10 }, // Head of the snake
        { x: 9, y: 10 },  // Second segment
        { x: 8, y: 10 },  // Third segment
      ];
      this.direction = 'right'; // Initial direction
      this.justAte = false;
    }
  
    move(food) {
      const head = { ...this.body[0] };
  
      switch (this.direction) {
        case 'up':
          head.y -= 1;
          break;
        case 'down':
          head.y += 1;
          break;
        case 'left':
          head.x -= 1;
          break;
        case 'right':
          head.x += 1;
          break;
        default:
          break;
      }
  
      this.body.unshift(head);
  
      if (head.x === food.x && head.y === food.y) {
        this.justAte = true;
      } else {
        this.justAte = false;
        this.body.pop(); // Remove the tail unless food is eaten
      }
    }
  
    changeDirection(newDirection) {
        const oppositeDirection = {
          up: 'down',
          down: 'up',
          left: 'right',
          right: 'left',
        };
      
        if (newDirection !== oppositeDirection[this.direction]) {
          this.direction = newDirection;
        }
      }
    get head() {
      return this.body[0];
    }
  }
  
  export default Snake;
  