import React from 'react';

const UserInfo = ({ memberName, organisationName, selectedEnv, appEnv, appName }) => {
  return (
    <div className="user-info">
      <h1>Welcome, {memberName} <strong>({organisationName})</strong></h1>
      {selectedEnv && (
        <p>Specter Environment: <strong className="capitalize">{selectedEnv}</strong></p>
      )}
      {appEnv && appName && (
        <p>You are currently accesing the <strong className="capitalize">{appEnv}</strong> environment of <strong>{appName}</strong> app.</p>
      )}
    </div>
  );
};

export default UserInfo;
