// AuthPage.js
import React, { useState, useEffect } from 'react'; // Import useEffect here
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer,toastId } from 'react-toastify';

const AuthPage = ({ setAuthToken, setGameData, setPlayerId }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);


   // Check local storage for existing auth token
   useEffect(() => {
    const token = localStorage.getItem('authToken');
    const playerId = localStorage.getItem('playerId');
    if (token && playerId) {
      setAuthToken(token);
      setPlayerId(playerId);
      fetchGameInfo(token);
    }
  }, []);


  const toggleAuthMode = () => {
    setIsRegistering((prev) => !prev);
  };

  const fetchGameInfo = async (token) => {
    const toastId = toast.loading('Fetching game info...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
    });

    try {
      const response = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-info',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Api-Key': '7e678ce98895855516462e8468322b1f7dbba1899fa869a265e09d22935690d5',
            'env': 'console',
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch game info');
      }

      setGameData({
        name: data.data.name,
        iconUrl: data.data.iconUrl,
        howTo: data.data.howTo || 'No instructions available.',
      });

      toast.update(toastId, {
        render: 'Game info fetched successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error fetching game info:', error);
      toast.update(toastId, {
        render: `Error fetching game info: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleAuth = async () => {
    const payload = {
      username,
      password,
      projectId: '042fe667-fe9f-4234-b44a-3c93e416a0f4', // replace with your actual project ID
      createAccount: isRegistering,
    };

    setIsLoading(true); // Start loading
    const toastId = toast.loading('Processing...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
    });

    try {
      const response = await fetch(
        `https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/login-username`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Api-Key': '7e678ce98895855516462e8468322b1f7dbba1899fa869a265e09d22935690d5',
            env: 'console',
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Authentication failed');
      }
  
      setAuthToken(data.data.accessToken);
      setPlayerId(data.data.user.id); // Set the player ID here
       // Save auth token and player ID to local storage
       localStorage.setItem('authToken', data.data.accessToken);
       localStorage.setItem('playerId', data.data.user.id);
      toast.update(toastId, {
        render: 'You have logged in Successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      // Fetch game info after successful login
      await fetchGameInfo(data.data.accessToken);
    } catch (error) {
      toast.update(toastId, {
        render: `Error: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="auth-container">
      <h2>{isRegistering ? 'Register' : 'Log In'}</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleAuth} disabled={isLoading}>
        {isLoading ? 'Loading...' : isRegistering ? 'Register' : 'Log In'}
      </button>
      <p onClick={toggleAuthMode}>
        {isRegistering ? 'Already have an account? Log In' : 'Need an account? Register'}
      </p>
    
    </div>
  );
};

export default AuthPage;
