import React, { useState } from 'react';
import SnakeBoard from './SnakeBoard';
import AuthPage from './AuthPage';
import SnakeGameHome from './SnakeGameHome';
import Leaderboard from './Leaderboard';

// Modal Component
const InstructionsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="snake-info-modal-overlay" onClick={onClose}>
      <div className="snake-info-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>How to Play</h2>
        <ul>
          <li>Press 'R' to start the game.</li>
          <li>Use the arrow keys to move the snake.</li>
          <li>Eat the red food to grow and score points.</li>
          <li>Avoid colliding with the walls or yourself.</li>
          <li>Each food item eaten increases your score by 10 points.</li>
          <li>Every 5 food items grant a bonus of 20 points.</li>
          <li>You gain a bonus of 50 points when the intensity level increases.</li>
          <li>Intensity Level increases every 100 points; the game speeds up slightly with each level.</li>
          <li>The game ends if you hit a wall or yourself.</li>
        </ul>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

// Main Game Component
const SnakesGame = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [isHomeScreen, setIsHomeScreen] = useState(true);
  const [gameData, setGameData] = useState(null); // Initialize gameData here
  const [matchId, setMatchId] = useState(null);
  const [matchSessionId, setMatchSessionId] = useState(null);
  const [matchName, setMatchName] = useState('Snake Game');
  const [playerId, setPlayerId] = useState(null); // Add player ID
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);


  const startGame = () => {
    setIsPlaying(true);
    setIsGameOver(false);
  };

  const restartGame = () => {
    setIsPlaying(false);
    setIsGameOver(false);
    setTimeout(() => setIsPlaying(true), 100);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const openLeaderboard = () => {
    setIsLeaderboardOpen(true);
  };

  const closeLeaderboard = () => {
    setIsLeaderboardOpen(false);
  };

    // New function to handle going back to home screen
    const goBackToHome = () => {
        setIsHomeScreen(true);
      };
    

  return (
    <div>
      {!authToken ? (
        <AuthPage
          setAuthToken={setAuthToken}
          setGameData={setGameData}
          setPlayerId={setPlayerId}
        />
      ) : isHomeScreen ? (
        gameData && ( // Ensure home screen only shows after data is loaded
          <SnakeGameHome
            gameData={gameData}
            authToken={authToken}
            onStartGame={() => setIsHomeScreen(false)}
            setMatchId={setMatchId}
            setMatchName={setMatchName}
          />
        )
      ) : (
        <div>
              <div className='snake-go-back'>
            <button className="back-button" onClick={goBackToHome}>
              Back
            </button>
            </div>
          <div className='snake-header'>
         
          
            <h1>{matchName}</h1> {/* Display match name */}
            <button className="close-button" onClick={openModal}>
              <img src={`${process.env.PUBLIC_URL}/assets/info.svg`} alt="Close" className="icon" />
            </button>
            <button className="close-button" onClick={openLeaderboard}>
        
      <img src="/assets/lb.svg" alt="No Results" className="no-results-image" />
            </button>
          </div>
          <h4>Press R to {isGameOver ? 'Restart' : 'Start'}!</h4>
          <div className='snake-score'>
            <p>Score: {score}</p>
            <p>Intensity: {level}</p>
          </div>
          <SnakeBoard
            authToken={authToken}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            setIsGameOver={setIsGameOver}
            isGameOver={isGameOver} // Pass isGameOver as a prop
            setScore={setScore}
            setLevel={setLevel}
            matchId={matchId} // Pass matchId
            playerId={playerId} // Pass playerId
            setMatchSessionId={setMatchSessionId} // Pass setMatchSessionId
            matchSessionId={matchSessionId} // Pass matchSessionId to view logs correctly
          />
          <InstructionsModal isOpen={isModalOpen} onClose={closeModal} />
          {isLeaderboardOpen && <Leaderboard authToken={authToken} onClose={closeLeaderboard} />}
        </div>
      )}
    </div>
  );
};

export default SnakesGame;

