import React, { useRef, useEffect } from 'react';
import SnakeGameEngine from './SnakeGameEngine';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const SnakeBoard = ({
  authToken,
  playerId,
  matchId,
  setMatchSessionId,
  isPlaying,
  setIsPlaying,
  setIsGameOver,
  setScore,
  setLevel,
  matchSessionId,
  isGameOver, // Receive isGameOver as a prop
}) => {
  const canvasRef = useRef(null);
  const gameRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      gameRef.current = new SnakeGameEngine(context, 20, 20, setScore, setLevel);

      const gameLoop = () => {
        if (isPlaying && gameRef.current) {
          const gameOver = gameRef.current.update();
          if (gameOver) {
            setIsGameOver(true);
            setIsPlaying(false);
            endMatchSession();
          } else {
            gameRef.current.draw();
            setTimeout(gameLoop, gameRef.current.speed);
          }
        }
      };

      if (isPlaying) {
        gameLoop();
      }

      return () => {
        clearTimeout(gameLoop);
      };
    }
  }, [isPlaying, setIsGameOver]); // Update dependencies

  const startMatchSession = async () => {
    if (!matchId || !playerId) return;
    try {
      const response = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/start-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
            'Api-Key': '7e678ce98895855516462e8468322b1f7dbba1899fa869a265e09d22935690d5',
            env: 'console',
          },
          body: JSON.stringify({
            matchId,
            userInfo: [{ id: playerId }],
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to start match session');
      }

      setMatchSessionId(data.data.matchSessionId);
      console.log('Match session started:', data.data.matchSessionId);
    } catch (error) {
      console.error('Error starting match session:', error);
    }
  };

  const endMatchSession = async () => {
    if (!matchSessionId) {
      console.error('No match session ID available to end the session.');
      return;
    }

    try {
      const response = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/end-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
            'Api-Key': '7e678ce98895855516462e8468322b1f7dbba1899fa869a265e09d22935690d5',
            env: 'console',
          },
          body: JSON.stringify({
            matchSessionId,
            userInfo: [{ id: playerId, outcome: gameRef.current.score }],
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to end match session');
      }

      console.log('Match session ended:', data.data.matchSessionId);
    } catch (error) {
      console.error('Error ending match session:', error);
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const directions = {
      ArrowUp: 'up',
      ArrowDown: 'down',
      ArrowLeft: 'left',
      ArrowRight: 'right',
    };

    if (directions[e.key] && !gameRef.current.directionChanged) {
      gameRef.current.snake.changeDirection(directions[e.key]);
      gameRef.current.directionChanged = true;
    }

    if (e.key === 'r' || e.key === 'R') {
        if (!isPlaying) {
          if (isGameOver) {
            gameRef.current.reset();
            setIsGameOver(false);
          }
          setIsPlaying(true);
          startMatchSession();
        }
      }
    };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPlaying, isGameOver]); // Update dependencies

  return (
    <div className="canvas-container">
      <canvas ref={canvasRef} width={400} height={400}></canvas>
    </div>
  );
};

export default SnakeBoard;
