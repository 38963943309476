import Snake from './Snake';

class SnakeGameEngine {
    constructor(context, gridSize = 20, tileCount = 20, setScore, setLevel) {
      this.context = context;
      this.gridSize = gridSize;
      this.tileCount = tileCount;
      this.snake = new Snake();
      this.food = this.randomPosition();
      this.setScore = setScore;
      this.setLevel = setLevel;
      this.score = 0;
      this.level = 1;
      this.foodEaten = 0;
      this.directionChanged = false; // Add this flag
  
      // Create a subtle noise pattern
      this.patternCanvas = document.createElement('canvas');
      this.patternCanvas.width = gridSize;
      this.patternCanvas.height = gridSize;
      const patternContext = this.patternCanvas.getContext('2d');
  
      // Fill the pattern canvas with a subtle noise pattern
      patternContext.fillStyle = '#e0e0e0';
      patternContext.fillRect(0, 0, this.patternCanvas.width, this.patternCanvas.height);
      patternContext.fillStyle = 'rgba(190, 190, 190, 0.2)';
      for (let i = 0; i < 100; i++) {
        patternContext.fillRect(
          Math.random() * this.patternCanvas.width,
          Math.random() * this.patternCanvas.height,
          1,
          1
        );
      }
  
      // Create dark mode noise pattern
      this.darkPatternCanvas = document.createElement('canvas');
      this.darkPatternCanvas.width = gridSize;
      this.darkPatternCanvas.height = gridSize;
      const darkPatternContext = this.darkPatternCanvas.getContext('2d');
  
      // Fill the dark pattern canvas with a subtle noise pattern
      darkPatternContext.fillStyle = '#2c2c2e';
      darkPatternContext.fillRect(0, 0, this.darkPatternCanvas.width, this.darkPatternCanvas.height);
      darkPatternContext.fillStyle = 'rgba(62, 62, 62, 0.2)';
      for (let i = 0; i < 100; i++) {
        darkPatternContext.fillRect(
          Math.random() * this.darkPatternCanvas.width,
          Math.random() * this.darkPatternCanvas.height,
          1,
          1
        );
      }
    }
  
    randomPosition() {
      const x = Math.floor(Math.random() * this.tileCount);
      const y = Math.floor(Math.random() * this.tileCount);
      return { x, y };
    }
  
    update() {
      this.snake.move(this.food);
      this.directionChanged = false; // Reset flag after each move
  
      if (this.snake.justAte) {
        this.food = this.randomPosition();
        this.score += 10;
        this.foodEaten++;
  
        if (this.foodEaten % 5 === 0) {
          this.score += 20;
        }
  
        if (this.score >= this.level * 100) {
          this.level++;
          this.score += 50;
          this.setLevel(this.level);
        }
  
        this.setScore(this.score);
      }
  
      return this.isGameOver();
    }
  
    isGameOver() {
      const head = this.snake.head;
      const hitWall = head.x < 0 || head.x >= this.tileCount || head.y < 0 || head.y >= this.tileCount;
      const hitSelf = this.snake.body.slice(1).some(segment => segment.x === head.x && segment.y === head.y);
  
      return hitWall || hitSelf;
    }
  
    draw() {
      // Fill the canvas with a noise pattern
      const pattern = this.context.createPattern(
        document.body.classList.contains('dark-mode') ? this.darkPatternCanvas : this.patternCanvas,
        'repeat'
      );
      this.context.fillStyle = pattern;
      this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height);
  
      // Draw food
      this.context.fillStyle = '#ff5e57';
      this.context.fillRect(this.food.x * this.gridSize, this.food.y * this.gridSize, this.gridSize, this.gridSize);
  
      // Draw snake
      this.context.fillStyle = '#16a085';
      this.snake.body.forEach(segment => {
        this.context.fillRect(segment.x * this.gridSize, segment.y * this.gridSize, this.gridSize, this.gridSize);
      });
    }
  
    reset() {
      this.snake = new Snake();
      this.food = this.randomPosition();
      this.score = 0;
      this.level = 1;
      this.foodEaten = 0;
      this.setScore(this.score);
      this.setLevel(this.level);
    }
  
    get speed() {
      return Math.max(100 - (this.level - 1) * 10, 50);
    }
  }
  
  export default SnakeGameEngine;
  