import React, { useState, useEffect, useRef } from 'react';
import './SessionLogViewer.css';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { io } from 'socket.io-client';

const SessionLogViewer = ({ onClose }) => {
  const [logs, setLogs] = useState([]);
  const [newLogIds, setNewLogIds] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [expandedDetails, setExpandedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState({});
  const logViewerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const logEntriesRef = useRef(null);
  const [socket, setSocket] = useState(null); // Add socket state
  const [wsEnabled, setWsEnabled] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedLogIds, setSelectedLogIds] = useState([]);
  const [recipient, setRecipient] = useState({ id: '', name: '' });

  const [slackChannels, setSlackChannels] = useState([]);
  const [slackUsers, setSlackUsers] = useState([]);
  const [showSlackDropdown, setShowSlackDropdown] = useState(false);
  const [sendStatus, setSendStatus] = useState('Send to Slack');
  const [searchTerm, setSearchTerm] = useState('');

  

  useEffect(() => {
    if (wsEnabled) {
      const socketInstance = io('https://specter-playground-proxy-server.azurewebsites.net');
      setSocket(socketInstance);
  
      socketInstance.on('connect', () => {
        console.log('Socket.IO connected');
      });
  
      socketInstance.on('disconnect', () => {
        console.log('Socket.IO disconnected');
      });
  
      socketInstance.on('newLog', (newLog) => {
        setLogs((prevLogs) => {
          const updatedLogs = [...prevLogs, newLog];
          return updatedLogs
            .filter((log, index, self) => index === self.findIndex((l) => l._id === log._id))
            .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        if (wsEnabled) {
          setNewLogIds((prevNewLogIds) => [...prevNewLogIds, newLog._id]);
          setTimeout(() => {
            setNewLogIds((prevNewLogIds) => prevNewLogIds.filter((id) => id !== newLog._id));
          }, 1000);
        }
  
        if (logEntriesRef.current) {
          setTimeout(() => {
            logEntriesRef.current.scrollTop = logEntriesRef.current.scrollHeight;
          }, 0);
        }
      });
  
      return () => {
        if (socketInstance) {
          socketInstance.disconnect();
        }
      };
    } else if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [wsEnabled]);
  

  useEffect(() => {
    const handleScroll = () => {
      if (logEntriesRef.current.scrollTop === 0 && page < totalPages) {
        fetchLogs(page + 1);
      }
    };

    const logEntriesElement = logEntriesRef.current;
    logEntriesElement.addEventListener('scroll', handleScroll);
    return () => logEntriesElement.removeEventListener('scroll', handleScroll);
  }, [page, totalPages]);

  useEffect(() => {
    const centerLogViewer = () => {
      if (logViewerRef.current) {
        logViewerRef.current.style.position = 'fixed';
        logViewerRef.current.style.top = `calc(50% - ${logViewerRef.current.offsetHeight / 2}px)`;
        logViewerRef.current.style.left = `calc(50% - ${logViewerRef.current.offsetWidth / 2}px)`;
      }
    };
    centerLogViewer();
  }, [loading]);

  useEffect(() => {
    fetchLogs(1, true);
  }, []);





  useEffect(() => {
    if (isSelectionMode) {
      fetchSlackChannels();
      fetchSlackUsers();
    }
  }, [isSelectionMode]);

  const fetchSlackChannels = async () => {
    try {
      const response = await fetch('https://proxy-slack-server-ff0dd5fc4e0c.herokuapp.com/api/slack/channels');
      const data = await response.json();
      if (data.ok) {
        setSlackChannels(data.channels);
      }
    } catch (error) {
      console.error('Error fetching Slack channels:', error);
    }
  };
  
  const fetchSlackUsers = async () => {
    try {
      const response = await fetch('https://proxy-slack-server-ff0dd5fc4e0c.herokuapp.com/api/slack/users');
      const data = await response.json();
  
      if (response.ok && data.members) {
        console.log('Users fetched:', data.members);
        setSlackUsers(data.members);
      } else {
        console.error('Failed to fetch users:', data.error || 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching Slack users:', error.message || 'Unknown error');
    }
  };
  

  const handleSlackDropdownClick = () => {
    setShowSlackDropdown(!showSlackDropdown);
  };

  const handleRecipientSelect = (id, name) => {
    setRecipient({ id, name });
    setShowSlackDropdown(false);
    setSearchTerm('');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = slackUsers.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredChannels = slackChannels.filter(channel =>
    channel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchLogs = async (pageNumber = 1, initial = false) => {
    setLoading(true);
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api/logs?page=${pageNumber}&limit=100`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (initial) {
        setLogs(data.logs.reverse());
        setTotalPages(data.totalPages);
        setPage(data.currentPage);
        if (logEntriesRef.current) {
          setTimeout(() => {
            if (logEntriesRef.current) {
              logEntriesRef.current.scrollTop = logEntriesRef.current.scrollHeight;
            }
          }, 0);
        }
      } else {
        if (logEntriesRef.current) {
          const currentScrollHeight = logEntriesRef.current.scrollHeight;
          const currentScrollTop = logEntriesRef.current.scrollTop;
          const newLogs = data.logs.reverse();
          setLogs((prevLogs) => [...newLogs, ...prevLogs]);
          setTimeout(() => {
            if (logEntriesRef.current) {
              logEntriesRef.current.scrollTop = logEntriesRef.current.scrollHeight - currentScrollHeight + currentScrollTop;
            }
          }, 0);
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
      setExpandedDetails((prevState) => {
        const newState = { ...prevState };
        delete newState[index];
        return newState;
      });
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const toggleDetailExpand = (index, detail) => {
    setExpandedDetails((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [detail]: !prevState[index]?.[detail],
      },
    }));
  };

  const toggleExpandAll = () => {
    if (expandedIndexes.length === logs.length) {
      setExpandedIndexes([]);
      setExpandedDetails({});
    } else {
      setExpandedIndexes(logs.map((_, index) => index));
      const allDetails = {};
      logs.forEach((_, index) => {
        allDetails[index] = {
          requestHeaders: true,
          requestBody: true,
          responseHeaders: true,
          responseBody: true,
        };
      });
      setExpandedDetails(allDetails);
    }
  };

  const handleCopy = (text, section, index, event) => {
    event.stopPropagation(); // Stop the event from bubbling up
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess((prevState) => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          [section]: 'Copied!',
        },
      }));
      setTimeout(() => {
        setCopySuccess((prevState) => ({
          ...prevState,
          [index]: {
            ...prevState[index],
            [section]: '',
          },
        }));
      }, 2000);
    });
  };

  const getNumericResponseTime = (responseTime) => {
    return typeof responseTime === 'string' ? parseInt(responseTime.replace('ms', '').trim(), 10) : responseTime;
  };
  
  const categorizeResponseTime = (responseTime) => {
    const numericResponseTime = getNumericResponseTime(responseTime);
    
    if (numericResponseTime < 200) {
      return 'response-time-excellent';
    } else if (numericResponseTime < 500) {
      return 'response-time-good';
    } else if (numericResponseTime < 1000) {
      return 'response-time-average';
    } else {
      return 'response-time-poor';
    }
  };
  

  const calculateRequestSize = (request) => {
    const headersSize = new Blob(Object.entries(request.headers).map(([key, value]) => `${key}: ${value}`)).size;
    const bodySize = request.body ? new Blob([JSON.stringify(request.body)]).size : 0;
    return headersSize + bodySize;
  };

  const calculateResponseSize = (response) => {
    const headersSize = new Blob(Object.entries(response.headers).map(([key, value]) => `${key}: ${value}`)).size;
    const bodySize = response.body ? new Blob([JSON.stringify(response.body)]).size : 0;
    return headersSize + bodySize;
  };

  logs.forEach(log => {
    log.requestSize = calculateRequestSize(log.request);
    log.responseSize = calculateResponseSize(log.response);
  });

  const categorizeStatus = (status) => {
    if (status >= 200 && status < 300) {
      return 'status-success';
    } else {
      return 'status-error';
    }
  };

  const categorizeMethod = (method) => {
    switch (method) {
      case 'POST':
        return 'method-post';
      case 'GET':
        return 'method-get';
      case 'PUT':
        return 'method-put';
      case 'DELETE':
        return 'method-delete';
      default:
        return '';
    }
  };


  const refreshLogs = async () => {
    setLogs([]); // Clear the current logs
    setPage(1); // Reset the page number
    setTotalPages(1); // Reset total pages
    setLoading(true); // Set loading to true
    await fetchLogs(1, true); // Fetch the latest logs
  };

  useEffect(() => {
    if (wsEnabled) {
      document.querySelector('.session-log-viewer').classList.add('real-time');
      document.querySelector('.log-entries').classList.add('real-time');
    } else {
      document.querySelector('.session-log-viewer').classList.remove('real-time');
      document.querySelector('.log-entries').classList.remove('real-time');
    }
  }, [wsEnabled]);


  const toggleSelectionMode = () => {
    setIsSelectionMode((prev) => !prev);
    setSelectedLogIds([]); // Clear selected log IDs when toggling the mode
  };

  const handleSelectLog = (logId) => {
    setSelectedLogIds((prevSelected) =>
      prevSelected.includes(logId)
        ? prevSelected.filter((id) => id !== logId)
        : [...prevSelected, logId]
    );
  };

  
  const sendLogsToSlack = (recipient) => {
    const selectedLogs = logs.filter((log) => selectedLogIds.includes(log._id));
    const payload = {
      channel: recipient.id,
      text: 'Selected Log Entries',
      attachments: selectedLogs.map((log) => ({
        text: JSON.stringify(log, null, 2),
      })),
    };
    
    fetch('https://proxy-slack-server-ff0dd5fc4e0c.herokuapp.com/api/send-to-slack', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setSendStatus('Sent Successfully');
          setTimeout(() => setSendStatus('Send to Slack'), 2000);
        } else {
          console.error('Error sending message:', data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  const handleWsToggle = () => {
    setWsEnabled((prev) => !prev);
    if (isSelectionMode) {
      setIsSelectionMode(false);
      setSelectedLogIds([]); // Clear selected log IDs
    }
  };
  
  
  return (
    <div className="session-log-viewer-container">
      <Draggable handle=".session-log-header, .draggable-left, .draggable-right, .draggable-bottom">
        <ResizableBox
          width={800}
          height={600}
          minConstraints={[400, 300]}
          maxConstraints={[window.innerWidth, window.innerHeight]}
        >
          <div className="session-log-viewer" ref={logViewerRef}>
            <div className="draggable-edge draggable-left"></div>
            <div className="draggable-edge draggable-right"></div>
            <div className="draggable-edge draggable-bottom"></div>
            <div className="draggable-corner draggable-bottom-right"></div>
            <div className="session-log-header">
              <button className="session-close-button" onClick={onClose}>
                ×
              </button>
          
{!wsEnabled && (
  <>
    <label className="selection-mode-toggle">
      <input
        type="checkbox"
        checked={isSelectionMode}
        onChange={toggleSelectionMode}
      />
      <span className="selection-slider"></span>
      <span className="selection-switch-label"></span>
     <div className="hover-label">Enable Selection Mode</div>
                  </label>
                  {isSelectionMode && (
          <div className="slack-dropdown">
            <div className="slack-dropdown-header" onClick={handleSlackDropdownClick}>
              {showSlackDropdown ? (
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus
                />
              ) : (
                <>
                  {recipient.name ? recipient.name : 'Select Recipient'}
                  <span className="slack-dropdown-arrow">&#9662;</span>
                </>
              )}
            </div>
            {showSlackDropdown && (
              <ul className="slack-dropdown-list">
                {filteredChannels.map((channel) => (
                  <li
                    key={channel.id}
                    className="slack-dropdown-option"
                    onClick={() => handleRecipientSelect(channel.id, channel.name)}
                  >
                    {channel.name}
                  </li>
                ))}
                {filteredUsers.map((user) => (
                  <li
                    key={user.id}
                    className="slack-dropdown-option"
                    onClick={() => handleRecipientSelect(user.id, user.name)}
                  >
                    {user.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </>
    )}


{isSelectionMode && selectedLogIds.length > 0 && (
  <button className="send-to-slack-button" onClick={() => sendLogsToSlack(recipient)}>
  {sendStatus}
</button>

)}
   <div className="session-log-title">
  API Activity Log
</div>
                <label className="socket-toggle-switch">
                <input
    type="checkbox"
    checked={wsEnabled}
    onChange={handleWsToggle}
  />
                  <span className="socket-slider"></span>
                  <span className="socket-switch-label"></span>
                  <div className="hover-label">Toggle Real Time</div>
                </label>
                <div className="expand-all-button-container">
                <button className="refresh-button" onClick={refreshLogs}>
                  <img src="/assets/refresh.svg" alt="Refresh" className="refresh-icon" />
                </button>
                <button className="expand-all-button" onClick={toggleExpandAll}>
                  {expandedIndexes.length === logs.length ? 'Collapse All' : 'Expand All'}
                </button>
      


              </div>
            </div>
            
            <div className="log-entries" ref={logEntriesRef}>
              {loading && logs.length === 0 ? (
    
    <div className="loader-container">
    <div className="loader">
      <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Loader Item 1" className="loader_svg" id="loader_sq1" />
      <img src={`${process.env.PUBLIC_URL}/assets/circle_stroke.svg`} alt="Loader Item 2" className="loader_svg" id="loader_sq2" />
      <img src={`${process.env.PUBLIC_URL}/assets/cross_stroke.svg`} alt="Loader Item 3" className="loader_svg" id="loader_sq3" />
      <img src={`${process.env.PUBLIC_URL}/assets/cross_filled.svg`} alt="Loader Item 4" className="loader_svg" id="loader_sq4" />
      <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Loader Item 5" className="loader_svg" id="loader_sq5" />
      <img src={`${process.env.PUBLIC_URL}/assets/circle_stroke.svg`} alt="Loader Item 6" className="loader_svg" id="loader_sq6" />
      <img src={`${process.env.PUBLIC_URL}/assets/cross_stroke.svg`} alt="Loader Item 7" className="loader_svg" id="loader_sq7" />
      <img src={`${process.env.PUBLIC_URL}/assets/cross_filled.svg`} alt="Loader Item 8" className="loader_svg" id="loader_sq8" />
      <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Loader Item 9" className="loader_svg" id="loader_sq9" />
    </div>
  </div>
  
              ) : logs.length === 0 ? (
                <p>No logs available.</p>
              ) : (
                logs.map((log, index) => (
                  <div className={`log-entry ${newLogIds.includes(log._id) ? 'new' : ''}`} key={index}>
                   
                   <div
    className={`log-entry-header ${isSelectionMode ? 'with-checkbox' : 'without-checkbox'}`}
    onClick={(e) => {
      if (!e.target.closest('.log-select-checkbox')) {
        toggleExpand(index);
      }
    }}
  >
    {isSelectionMode && (
      <input
        type="checkbox"
        className="log-select-checkbox"
        checked={selectedLogIds.includes(log._id)}
        onChange={(e) => {
          e.stopPropagation();
          handleSelectLog(log._id);
        }}
      />
    )}
  <div>{new Date(log.timestamp).toLocaleString()}</div>
  <div className={categorizeMethod(log.request.method)}>{log.request.method}</div>
  <div>{log.request.url}</div>
  <div className={categorizeStatus(log.response.status)}>
    {log.response.status}
  </div>
  <div className={categorizeResponseTime(log.response.responseTime)}>Response Time: {log.response.responseTime}</div>
  <div>{`Req: ${log.requestSize} B, Res: ${log.responseSize} B`}</div>
 
  <div className="expand-icon">{expandedIndexes.includes(index) ? '-' : '+'}</div>
</div>
                    {expandedIndexes.includes(index) && (
                      <div className="log-entry-details" style={{ display: 'block' }}>
                        <div>
                          <div
                            className="log-entry-section-header"
                            onClick={() => toggleDetailExpand(index, 'requestHeaders')}
                          >
                            <div className="expand-icon">
                              {expandedDetails[index]?.requestHeaders ? '-' : '+'}
                            </div>
                            <strong>Request Headers</strong>
                            {copySuccess[index]?.requestHeaders ? (
  <span className="log-copy-success">Copied!</span>
) : (
  <img
    src={`${process.env.PUBLIC_URL}/assets/copy.svg`}
    alt="Copy"
    className="log-copy-icon"
    onClick={(event) => handleCopy(JSON.stringify(log.request.headers, null, 2), 'requestHeaders', index, event)}
  />
)}

                          </div>
                          {expandedDetails[index]?.requestHeaders && (
                            <pre>{JSON.stringify(log.request.headers, null, 2)}</pre>
                          )}
                        </div>
                        <div>
                          <div
                            className="log-entry-section-header"
                            onClick={() => toggleDetailExpand(index, 'requestBody')}
                          >
                            <div className="expand-icon">
                              {expandedDetails[index]?.requestBody ? '-' : '+'}
                            </div>
                            <strong>Request Body</strong>
                            {copySuccess[index]?.requestBody ? (
  <span className="log-copy-success">Copied!</span>
) : (
  <img
    src={`${process.env.PUBLIC_URL}/assets/copy.svg`}
    alt="Copy"
    className="log-copy-icon"
    onClick={(event) => handleCopy(JSON.stringify(log.request.body, null, 2), 'requestBody', index, event)}
  />
)}
                          </div>
                          {expandedDetails[index]?.requestBody && (
                            <pre>{JSON.stringify(log.request.body, null, 2)}</pre>
                          )}
                        </div>
                        <div>
                          <div
                            className="log-entry-section-header"
                            onClick={() => toggleDetailExpand(index, 'responseHeaders')}
                          >
                            <div className="expand-icon">
                              {expandedDetails[index]?.responseHeaders ? '-' : '+'}
                            </div>
                            <strong>Response Headers</strong>
                            {copySuccess[index]?.responseHeaders ? (
  <span className="log-copy-success">Copied!</span>
) : (
  <img
    src={`${process.env.PUBLIC_URL}/assets/copy.svg`}
    alt="Copy"
    className="log-copy-icon"
    onClick={(event) => handleCopy(JSON.stringify(log.response.headers, null, 2), 'responseHeaders', index, event)}
  />
)}
                          </div>
                          {expandedDetails[index]?.responseHeaders && (
                            <pre>{JSON.stringify(log.response.headers, null, 2)}</pre>
                          )}
                        </div>
                        <div>
                          <div
                            className="log-entry-section-header"
                            onClick={() => toggleDetailExpand(index, 'responseBody')}
                          >
                            <div className="expand-icon">
                              {expandedDetails[index]?.responseBody ? '-' : '+'}
                            </div>
                            <strong>Response Body</strong>
                            {copySuccess[index]?.responseBody ? (
  <span className="log-copy-success">Copied!</span>
) : (
  <img
    src={`${process.env.PUBLIC_URL}/assets/copy.svg`}
    alt="Copy"
    className="log-copy-icon"
    onClick={(event) => handleCopy(JSON.stringify(log.response.body, null, 2), 'responseBody', index, event)}
  />
)}
                          </div>
                          {expandedDetails[index]?.responseBody && (
                            <pre>{JSON.stringify(log.response.body, null, 2)}</pre>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        </ResizableBox>
      </Draggable>
    </div>
  );
};

export default SessionLogViewer;



