import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './EmbeddedSitePopup.css';


const EmbeddedSitePopup = ({ onClose }) => {
    const [selectedSite, setSelectedSite] = useState('staging.specterapp.xyz');
    const [showSiteSelector, setShowSiteSelector] = useState(false);
    const popupRef = useRef()
    const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const centerPopup = () => {
      if (popupRef.current) {
        popupRef.current.style.position = 'fixed';
        popupRef.current.style.top = `calc(50% - ${popupRef.current.offsetHeight / 2}px)`;
        popupRef.current.style.left = `calc(50% - ${popupRef.current.offsetWidth / 2}px)`;
      }
    };
    centerPopup();
  }, []);

  const handleSiteChange = (site) => {
    setSelectedSite(site);
    setIsLoading(true); // Set loading state to true when site changes
  };


  const openInNewTab = () => {
    window.open(`https://${selectedSite}`, '_blank');
  };


  return (
    <div className="session-log-viewer-container">
      <Draggable handle=".session-log-header, .draggable-left, .draggable-right, .draggable-bottom">
        <ResizableBox
          width={800}
          height={600}
          minConstraints={[400, 300]}
          maxConstraints={[window.innerWidth, window.innerHeight]}
          resizeHandles={['se']}
        >
          <div className="session-log-viewer" ref={popupRef}>
            <div className="draggable-edge draggable-left"></div>
            <div className="draggable-edge draggable-right"></div>
            <div className="draggable-edge draggable-bottom"></div>
            
            <div className="session-log-header">
              <button className="session-close-button" onClick={onClose}>×</button>
            

              <div className="session-log-title">Specter Dashboard</div>
              <button className="session-new-tab-button" onClick={openInNewTab}>
  <img src={`${process.env.PUBLIC_URL}/assets/open_tab.svg`} alt="Open in New Tab" className="open-tab-icon" />
</button>
              <div className="site-selector-dropdown">
              <div className="site-selector-header" onClick={() => setShowSiteSelector(!showSiteSelector)}>
    {selectedSite === 'staging.specterapp.xyz' ? 'Staging' : 'Console'}
                  <span className="site-selector-arrow">&#9662;</span>
                </div>
            
                 {showSiteSelector && (
                    <ul className="site-selector-list">
                      <li className="site-selector-option" onClick={() => handleSiteChange('staging.specterapp.xyz')}>
                        Staging
                      </li>
                      <li className="site-selector-option" onClick={() => handleSiteChange('console.specterapp.xyz')}>
                        Console
                      </li>
                    </ul>
                
                )}
              </div>
            </div>
            {isLoading && (
          <div className="loader-container">
          <div className="loader">
            <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Loader Item 1" className="loader_svg" id="loader_sq1" />
            <img src={`${process.env.PUBLIC_URL}/assets/circle_stroke.svg`} alt="Loader Item 2" className="loader_svg" id="loader_sq2" />
            <img src={`${process.env.PUBLIC_URL}/assets/cross_stroke.svg`} alt="Loader Item 3" className="loader_svg" id="loader_sq3" />
            <img src={`${process.env.PUBLIC_URL}/assets/cross_filled.svg`} alt="Loader Item 4" className="loader_svg" id="loader_sq4" />
            <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Loader Item 5" className="loader_svg" id="loader_sq5" />
            <img src={`${process.env.PUBLIC_URL}/assets/circle_stroke.svg`} alt="Loader Item 6" className="loader_svg" id="loader_sq6" />
            <img src={`${process.env.PUBLIC_URL}/assets/cross_stroke.svg`} alt="Loader Item 7" className="loader_svg" id="loader_sq7" />
            <img src={`${process.env.PUBLIC_URL}/assets/cross_filled.svg`} alt="Loader Item 8" className="loader_svg" id="loader_sq8" />
            <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Loader Item 9" className="loader_svg" id="loader_sq9" />
          </div>
        </div>
            )}
            <iframe
              src={`https://${selectedSite}`}
              width="100%"
              height="100%"
              title="Specter Dashboard"
              style={{ border: 'none', borderRadius: '8px',marginTop:'5px' ,display: isLoading ? 'none' : 'block' }} // Added borderRadius here
              onLoad={() => setIsLoading(false)}
            />
          </div>
        </ResizableBox>
      </Draggable>
    </div>
  );
};

export default EmbeddedSitePopup;
